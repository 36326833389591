<template>
  <div class="page-box">
    <div class="cont">
      <div class="custom-box">
        <div class="s-store rowCenter" @click="showStore = true">
          <img src="../assets/images/home.png" class="local-icon" mode="aspectFill" />
          <span class="store-name" :class="{'active':(shopId!=null&&shopId!='0')}"> {{shopName?shopName:'请选择门店'}}
          </span>
          <img src="../assets/images/arrow4.png" class="arrow-icon" mode="aspectFill" />
        </div>
        <van-popup v-model:show="showStore" round position="bottom">
          <van-picker :columns="storeList" @cancel="showStore = false" @confirm="onConfirmStore" />
        </van-popup>
      </div>
      <div class="poster-box" v-show="posterUrl != null">
        <canvas id="myCanvas" class="poster-canvas" :width="canvasWidth" :height="canvasHeight" v-show="false" />
        <img :src="posterUrl" alt="" class="poster-img" :style="{width:canvasWidth+'px',height:canvasHeight+'px'}" />
      </div>
      <!-- 海报 -->
      <div class="poster-box" v-show="posterUrl == null">
        <div class="canvas-box" :style="{width: canvasWidth, height: canvasHeight}">
          <img src="../assets/images/pay-logo1.png" mode="aspectFill" class="pay-logo"/>
          <div class="bus-tag">精|选|商|家</div>
          <div class="white-div">
            <div class="business-name">{{shortName}}</div>
            <div class="ewm-box">
              <van-image :src="qrcodeUrl" class="qr-img" />
            </div>
            <div class="pay-type row">
              <div class="center type-item">
                <img src="../assets/images/wx.png" class="js-wx-icon" />
                <span class="js-wx-txt">微信支付</span>
              </div>
              <div class="center type-item">
                <img src="../assets/images/zfb.png" class="js-zfb-icon" />
                <span class="js-zfb-txt">支付宝支付</span>
              </div>
              <div class="center type-item">
                <img src="../assets/images/ysf.png" class="js-ysf-icon" />
                <span class="js-ysf-txt">云闪付</span>
              </div>
            </div>
            <!-- <div class="tel-box">
              <image src="/images/tel.png" class="tel-img"></image>
              <text class="tel-num">188 2042 8884</text>
            </div> -->
            <img src="../assets/images/arc.png" mode="aspectFill" class="arc-img" />
          </div>
        </div>     
      </div>
      <div class="save-btn">请长按保存门店收款码</div>
    </div>
    <!-- 二维码 -->
    <canvas v-show="false" id="canvas" />
  </div>
</template>
<script>
  import { getStoreListApi } from '@/api/store'
  import { getQrCodeApi } from '@/api/qrcode'
  import { uploadFileApi } from "@/api/common";
  import { Toast,Dialog } from 'vant'
  import QRCode from 'qrcode2';
  // import html2canvas from 'html2canvas';
  var toast1;
  export default {
    name: 'qrcode',
    data () {
      return {
        shopId: null,   //门店id
        shopName: null,
        showStore: false,   //是否显示门店列表弹窗
        storeList: [],   //门店列表
        shortName:null,
        
        canvasWidth: 375,  //画布宽
        canvasHeight: 400,   //画布高

        logoImg: require('@/assets/images/pay-logo1.png'),  //logo
        wxImg: require('@/assets/images/wx.png'),  //微信图标
        zfbImg: require('@/assets/images/zfb.png'),  //支付宝图标
        ysfImg: require('@/assets/images/ysf.png'),  //云闪付图标
        arcImg: require('@/assets/images/arc.png'),  //弧形底部
        // 二维码的dataUrl
        qrcodeUrl: '',
        // 合成图片
        posterUrl: null,  //base64
        netPosterUrl: null, //网络图片
      }
    },
    created () {
      var that = this
      that.shortName = that.$route.query && that.$route.query.shortName
      toast1 = Toast.loading({
        message: '付款码生成中...',
        forbidClick: true,
      });
      this.canvasWidth = document.documentElement.clientWidth;
      this.canvasHeight = document.documentElement.clientHeight-35;
      
      this.getStoreList(); //获取门店列表
    },
    methods: {
      //获取门店列表
      getStoreList () {
        const that = this;
        const param = {
          // startPage: false,          
        }
        getStoreListApi(param).then(res => {          
          if (res && res.rows && res.rows.length > 0) {
            let data = res.rows;
            that.shopId = data[0].id;
            that.shopName = data[0].shopName;
            for (let d of data) {
              d.value = d.id;
              d.text = d.shopName;
            }
            that.storeList = data;
            that.getQrCode(data[0].id);  //获取二维码链接地址
          }else{
            if(toast1){
              toast1.clear();
            }
            Dialog.confirm({
              title: '提示',
              message:'抱歉，您还未绑定门店，绑定门店后才可查看门店收款码，是否立即去绑定门店？',
            }).then(() => {
              // on confirm
              that.$router.push('/store-add');
            }).catch(() => {
              // on cancel
            });
          }
        });
      },
      //选择门店
      onConfirmStore (value) {
        const that = this;
        if (value) {
          that.shopId = value.value;
          that.shopName = value.text;
          that.getQrCode(that.shopId);
        }
        that.showStore = false;
      },
      //获取二维码链接地址
      getQrCode (id) {
        const that = this;
        getQrCodeApi(id).then(res => {
          if (res && res.data) {
            let data = res.data;
            that.createQrcode(data); //生成二维码
          }else{
            if(toast1){
              toast1.clear();
            }
          }
        });
      },
      //生成二维码
      createQrcode (url) {
        let that = this;
        let tmp = document.createElement('div');
        let qrcode = new QRCode(tmp, {
            text: url,
            width: 193,
            height: 193,
            // colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        })
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = require('@/assets/images/mini-pay-icon.png');
        logo.onload = function() {
            let canvas = qrcode._el.getElementsByTagName('canvas')[0];
            var ctx = canvas.getContext("2d");
            ctx.drawImage(logo, 193 * 0.5 - 22, 193 * 0.5 - 22, 54, 54);
            let data = canvas.toDataURL('image/png', 1);
            that.qrcodeUrl = data;
            // 销毁临时dom
            tmp = null;
            qrcode = null;
            logo = null;
            // 需要合成海报的图片
            that.drawAndShareImage(that.qrcodeUrl)
        }
      },
      //img1背景图、img2二维码
      drawAndShareImage(qrcodeImg,merId) {
        let that = this;
        try{
          let img1 = that.logoImg;
          let img2 = qrcodeImg;
          let img3 = that.wxImg;
          let img4 = that.zfbImg;
          let img5 = that.ysfImg;
          let img6 = that.arcImg;
          let canvas = document.getElementById('myCanvas');
          var ratio = 2;
          var w = that.canvasWidth;
          var h = that.canvasHeight;
          canvas.width = w* ratio; // 实际渲染像素
          canvas.height = h * ratio; // 实际渲染像素
          canvas.style.width = `${w}px`; // 控制显示大小
          canvas.style.height = `${h}px`; // 控制显示大小K

          let ctx = canvas.getContext('2d');
          this.ctx = ctx;
          this.canvas = canvas;
          ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
          ctx.rect(0, 0, canvas.width, canvas.height);          
          ctx.fillStyle = '#FA3474';
          ctx.fill();
          var top = 0;
          // logo
          let myImage = new Image();
          myImage.src = img1;
          myImage.crossOrigin = 'Anonymous';
          myImage.onload = function() {
              top+=30
              ctx.drawImage(myImage, w/2-153/2, top, 153, 30);
              
              // 精|选|商|家                  
              top = top+30+28;
              ctx.font="13px PingFangSC-Regular";
              ctx.fillStyle="#ffffff";
              ctx.textAlign = 'center';//文字水平居中
              ctx.fillText('精 | 选 | 商 | 家',w/2,top);

              // 白色背景框
              top = top+20;
              var btop = top;
              that.roundRect(ctx,w/2-324/2,top,324,408,18, '#fff', 1);

              // 商家名称
              top = top+44;
              ctx.font = 'bold 23px PingFang SC';
              ctx.fillStyle = '#333333'
              ctx.textAlign = 'center';//文字水平居中
              ctx.fillText(that.shortName, w/2, top);

              // 红色边框
              top = top+24;
              that.roundRect(ctx,w/2-234/2,top,234,234,9, '#FA3474', 2);
              
              // 二维码
              top = top+20;
              let myImage2 = new Image();
              myImage2.src = img2;
              myImage2.crossOrigin = 'Anonymous';
              myImage2.onerror=function(e){
                that.$modal.msgError("收款码生成失败，请稍后重新生成！");
                if(toast1){
                  toast1.clear();
                }
              }
              myImage2.onload = function() {
                  ctx.drawImage(myImage2, w/2-193/2, top, 193, 193);

                  // 支付方式
                  top = top+234;
                  let myImage3 = new Image();
                  myImage3.src = img3;
                  myImage3.crossOrigin = 'Anonymous';
                  myImage3.onload = function() {
                      ctx.drawImage(myImage3, w/2-29/2-53-29, top, 29, 29);
                      
                      let myImage4 = new Image();
                      myImage4.src = img4;
                      myImage4.crossOrigin = 'Anonymous';
                      myImage4.onload = function() {
                          ctx.drawImage(myImage4, w/2-29/2, top, 29, 29);

                          let myImage5 = new Image();
                          myImage5.src = img5;
                          myImage5.crossOrigin = 'Anonymous';
                          myImage5.onload = function(){
                            ctx.drawImage(myImage5, w/2+29/2+53, top, 29, 29);

                            top = top+40;
                            ctx.font = '7px PingFang SC';
                            ctx.fillStyle="#323333";
                            ctx.textAlign = 'center';//文字水平居中
                            ctx.fillText('微信支付',w/2-29-53,top);
                            ctx.fillText('支付宝支付',w/2,top);
                            ctx.fillText('云闪付',w/2+29+53,top);

                            // 底部弧形
                            let myImage6 = new Image();
                            myImage6.src = img6;
                            myImage6.crossOrigin = 'Anonymous';
                            myImage6.onload = function(){
                              ctx.drawImage(myImage6, w/2-324/2, btop+(408-50), 324, 50);

                              let base64 = canvas.toDataURL('image/png',1);
                              let img = document.getElementById('myCanvas');
                              img.setAttribute('src', base64);
                              if (base64) {
                                if(toast1){
                                  toast1.clear();
                                }
                                that.showPoster = true;
                                // 赋值相应海报上
                                that.posterUrl = base64;
                              }                                 
                            }
                         
                          }
                      };
                  };
              };
          };
        }catch(e){
          if(toast1){
            toast1.clear();
          }
        }
      },
      /**绘制有圆角的矩形框
        * @param {CanvasContext} ctx canvas上下文
        * @param {number} x 圆角矩形选区的左上角 x坐标
        * @param {number} y 圆角矩形选区的左上角 y坐标
        * @param {number} w 圆角矩形选区的宽度
        * @param {number} h 圆角矩形选区的高度
        * @param {number} r 圆角的半径
        */
      roundRect(ctx, x, y, w, h, r, color, type) {
        // 开始绘制
        ctx.save();
        ctx.beginPath();
        // 因为边缘描边存在锯齿，最好指定使用 transparent 填充
        // 这里是使用 fill 还是 stroke都可以，二选一即可
        if(type == 2){
          ctx.fillStyle = '#fff';
          // ctx.setStrokeStyle('#BEBEBE');
          ctx.strokeStyle = color;
        }else{
          ctx.fillStyle = color;
        }
        // ctx.setFillStyle('transparent')
        // ctx.setStrokeStyle('transparent')
        // 左上角绘制左上角圆弧
        ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5)
        // 右上角
        ctx.arc(x + w - r, y + r, r, Math.PI * 1.5, Math.PI * 2)
        // 右下角
        ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * 0.5)
        // 左下角
        ctx.arc(x + r, y + h - r, r, Math.PI * 0.5, Math.PI)
        ctx.closePath()
        // 这里是使用 fill 还是 stroke都可以，二选一即可，但是需要与上面对应
        if(type == 2){
          ctx.stroke()
        }else{
          ctx.fill()
        }
        // 剪切
        ctx.clip()
        // 从原始画布中剪切任意形状和尺寸。一旦剪切了某个区域，则所有之后的绘图都会被限制在被剪切的区域内（不能访问画布上的其他区域）。可以在使用 clip 方法前通过使用 save 方法对当前画布区域进行保存，并在以后的任意时间通过restore方法对其进行恢复。
        // restore 进行恢复，使其能够访问画布上的其他区域
        ctx.restore()
      },
      //下载收款码
      handleDownload(){
        let that = this;
        let content = this.posterUrl;
        let blob = this.dataURLtoBlob(content,'收款码.png'); 
        let formData = new FormData()
        formData.append("file",blob)
        uploadFileApi(formData).then(res=>{
          if (res.code == 200) {
            let url = res.data.url;
            that.netPosterUrl = url;
            // 创建一个a标签
            var link = document.createElement('a')
            //将生成的图片url赋值给a标签的href属性
            link.href = url
            //设置下载的文件名
            link.download = 'qrcode.png'
            // 将a标签插入dom中
            document.body.appendChild(link)
            //模拟点击事件触发下载
            link.click()
            // link.dispatchEvent();
            // 完成之后销毁创建的a标签
            document.body.removeChild(link);
          } else {
            Toast(res.msg);
          }
        })
      },
      dataURLtoBlob: function(dataurl,fileName) { 
          var arr = dataurl.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);
          while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr],fileName, { type: mime });
      },
    },
    mounted () {
      // this.$nextTick(() => {
      //   document.body.setAttribute('style', 'background:#FA3474')
      // })
    }
  }
</script>
<style lang="less" scoped>
  @import '../assets/style/mixin';

  .page-box {
    background-color: #FA3474;
    font-size: 12px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 0;
  }

  .cont {
    /* margin: 23px 15px 0; */
    /* background-color: #ffffff; */
    flex: 1;
  }

  .custom-box {
    padding: 0 28px;
    /* height: 50px; */
    margin-top: 15px;
    height: 20px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }

  .local-icon {
    width: 16px;
    height: 14px;
  }

  .store-name {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-left: 6px;
    margin-right: 7.5px;
  }

  .arrow-icon {
    width: 12px;
    height: 12px;
    margin-top: -5px;
  }

  .pay-type {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* justify-content: space-around; */
    /* padding: 15px 30px 12px; */
    font-size: 12px;
  }

  .pay-type text {
    line-height: 1;
    font-size: 8px;
    font-weight: 700;
    margin-top: 3px;
  }

  .pay-type img {
    width: 29px;
    height: 29px;
    margin-bottom: 5px;
  }

  
  /* 海报 */
  .poster-box{
    position: relative;
  }
  canvas{
    position: absolute;
    z-index: -1;
    left: -1000px;
  }
  .canvas-box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pay-logo{
    width: 152.5px;
    height: 30px;
    margin-top: 30px;
  }
  .bus-tag{
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 5px;
    margin-top: 13px;
  }
  .white-div{
    width: 324px;
    height: 408.5px;
    background-color: #ffffff;
    border-radius: 17.5px;  
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .arc-img{
    width: 324px;
    height: 50px;
    position:absolute;
    bottom: 0;
    left: 0;
  }
  .business-name{
    font-size: 23px;
    font-weight: 800;
    color: #333333;
    margin-top: 20px;
  }
  .ewm-box{
    width: 230px;
    height: 230px;
    border: 1px solid #FA3474;
    border-radius: 9px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .qr-img{
    width: 193px;
    height: 193px;
  }

  .pay-type {
    align-items: center;
    justify-content: center;
    margin-top: 18px;
  }
  .pay-type .type-item{
    margin: 0 15px;
  }
  .pay-type span{
    line-height: 1;
    margin-top: 5px;
    font-size: 7px;
    font-weight: bold;
    color: #323333;
  }
  .pay-type image{
    width: 29px;
    height: 29px;
  }
  .tel-box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
  }
  .tel-img{
    width: 17.5px;
    height: 317.5px;
  }
  .tel-num{
    font-size: 13.5px;
    font-weight: 400;
    color: #333333;
    margin-left: 4px;
  }

  .opt-box .opt{
    position: fixed;
    bottom: 0rpx;
    left: 25.5px;
    right: 25.5px;  
    padding-bottom: 31.5px;
  }
  .apply-btn {
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 44px;  
    margin-bottom: env(safe-area-inset-bottom);
    font-size: 15px;
    font-weight: 500;
    color: #FA3474;
  }

  .save-btn{
    position: absolute;
    bottom: 33px;
    left: 50%;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    width: 200px;
    margin-left: -100px;
  }
</style>